import { useCallback, useEffect, useMemo, useState } from "react";

import { Trans } from "react-i18next";
import { t } from "i18next";

import { showNotification } from "../../utils/notification";
import Loader from "../global/Loader";
import MeasurementCard from "./MeasurementCard";
import { apiAddMeasurementToCart, apiGetCartMeasurements, apiRemoveMeasurementFromCart } from "../../app/measurementApi";
import { getInformativeMeasurements } from "../../app/measurement";
import AddMeasurementForm from "./AddMeasurementForm";
import { Dropdown, DropdownButton } from "react-bootstrap";
import useCartData from "../../app/useCartData";
import useCartApi from "../../app/useCartApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CartMeasurements = () => {
    const { cart } = useCartData();
    const cartApi = useCartApi();

    const [isLoading, setIsLoading] = useState(false);
    const [measurements, setMeasurements] = useState([]);

    const cartId = useMemo(() => cart?.cartId, [cart]);
    const informativeMeasurements = useMemo(() => getInformativeMeasurements(measurements), [measurements]);

    const updateMeasurements = useCallback(async (backgroundUpdate) => {
        if (!cartId) {
            return;
        }

        if (!backgroundUpdate) {
            setIsLoading(true);
        }

        try {
            // fetch finished measurements
            let data = await apiGetCartMeasurements(cartId, true);

            // fetch non-finished measurements for last 5 minutes
            const data2 = await apiGetCartMeasurements(cartId, false, 5 * 60);
            data.push(...data2);

            if (!backgroundUpdate) {
                setIsLoading(false);
            }

            setMeasurements(data);
        } catch (err) {
            console.log(err);
            showNotification(t("Error loading cart measurements") + ": " + err, 'danger');

            if (!backgroundUpdate) {
                setIsLoading(false);
            }
        }
    }, [cartId]);

    async function addMeasurement(measurementId) {
        if (!cartId) {
            showNotification(t("cmNoCart"), 'danger');
            return;
        }

        try {
            await apiAddMeasurementToCart(measurementId, cartId)
        } catch (err) {
            console.log(err);
            showNotification(t("cmAddToCartFailed") + ": " + err, 'danger');
            return;
        }

        await updateMeasurements();
    }

    async function removeMeasurement(measurementId) {
        if (!cartId) {
            showNotification(t("cmNoCart"), 'danger');
            return;
        }

        try {
            await apiRemoveMeasurementFromCart(measurementId, cartId)
        } catch (err) {
            console.log(err);
            showNotification(t("cmRemoveFromCartFailed") + ": " + err, 'danger');
            return;
        }

        await updateMeasurements();
    }

    useEffect(() => {
        if (cartId) {
            updateMeasurements();

            // Update timer
            const timer = setInterval(() => {
                updateMeasurements(true);
            }, 5 * 1000);

            return () => clearInterval(timer);
        }
    }, [cartId, updateMeasurements]);

    // Early returns
    if (!cartId) {
        return (
            <div>
                <div className="alert alert-primary my-3" role="alert">
                    <p><Trans>Synchronize shopping list to view synchronized measurements.</Trans></p>
                </div >
            </div >
        )
    }

    if (isLoading) {
        return <Loader />
    }

    // Main render
    return (
        <>
            {informativeMeasurements.length > 0 ? (
                <div className="row mt-3">
                    {informativeMeasurements.map(measurement => (
                        <div className="col-sm-6 col-lg-3 mb-3 d-flex align-items-stretch" key={measurement.measurementId}>
                            <MeasurementCard measurement={measurement} handleRemove={removeMeasurement} />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="row mt-3">
                    <div className="col-12">
                        <p><Trans>No measurements</Trans></p>
                    </div >
                </div >
            )}

            <div className="d-flex justify-content-between align-items-center mt-3">
                <AddMeasurementForm handleAdded={addMeasurement} />

                {cartId && (
                    <DropdownButton id="dropdown-export" title={<Trans>cmExport</Trans>} variant="secondary">
                        <Dropdown.Item href={cartApi.getMeasurementsExportUrl(cartId, "docx")}><FontAwesomeIcon icon="file-word" /> <Trans>cmExportCartDocx</Trans></Dropdown.Item>
                        <Dropdown.Item href={cartApi.getMeasurementsExportUrl(cartId, "docx", true)}><FontAwesomeIcon icon="file-word" /> <Trans>cmExportCartDocxWithImages</Trans></Dropdown.Item>
                        <Dropdown.Item href={cartApi.getMeasurementsExportUrl(cartId, "xlsx")}><FontAwesomeIcon icon="file-excel" /> <Trans>cmExportCartXlsx</Trans></Dropdown.Item>
                        <Dropdown.Item href={cartApi.getMeasurementsExportUrl(cartId, "xlsx", true)}><FontAwesomeIcon icon="file-excel" /> <Trans>cmExportCartXlsxWithImages</Trans></Dropdown.Item>
                    </DropdownButton>
                )}
            </div>
        </>
    );
};

export default CartMeasurements;
